import styled from 'styled-components';

export const Body = styled.div`
  background: #fff;
  color: #661988;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;
export const InnerBody = styled.div`
  width: 860px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  padding-bottom: 30px;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    height: auto;
    padding-top: 10px;
  }
`;

export const BodyContent = styled.div`
  width: 650px;
  margin: 0 auto;
  @media (max-width: 991px) {
    width: 100%;
    padding: 20px 35px 0 35px;
  }
`;

export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: #e5e5e5;
  margin: 50px 0;
  clear: both;
  opacity: 1;
  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;

export const HeaderH1 = styled.h1`
  text-transform: uppercase;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-size: 30px;
  line-height: 38px;
  padding-top: 0px;
  margin: 0 0 25px 0;
  font-weight: normal !important;
  color: #6e2b8b;
  @media (max-width: 991px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

export const HeaderH2 = styled.h2`
  font-family: 'Figtree-Medium', arial, sans-serif;
  font-weight: normal !important;
  color: #6e2b8b;
  line-height: 36px;
  margin: 0;
  font-size: 25px;
  padding-top: 0px;
`;

export const ParaText = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: #666;
  margin: 0 0 20px 0;
  padding: 0;
  b {
    font-family: 'Figtree-Bold', arial, sans-serif;
    font-weight: bold;
  }
`;

export const SuperScriptStyle = styled.sup`
  vertical-align: top;
  font-size: 18px;
  line-height: 30px;
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #ffc018;
  :hover {
    text-decoration: none;
    color: #6e2b8b;
  }
`;

export const FormBody = styled.div`
  width: 450px;
  margin: 0 auto;
  padding: 25px 0;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
  }
  
  #mktoStyleLoaded {
  /* css load detection, do not remove */
  color:#123456 !important;
}

.mktoForm {
	font-family: 'Figtree-Medium', arial, sans-serif !important;
	color: #666 !important;
}

.mktoForm fieldset {
  border:1px solid #aeb0b6 !important;
  -webkit-border-radius:0 !important;
  -moz-border-radius:0 !important;
  border-radius:0 !important;
}
.mktoForm fieldset legend{
  padding:0 1em !important;
}

.mktoForm .mktoLabel {
  padding-top: 0.7em !important;
}

.mktoForm label.mktoLabel[for="ExpressConsent"],
.mktoForm label.mktoLabel[for="ExpressConsent"] span,
.mktoForm label.mktoLabel[for="ExpressConsent"] a {
  font-family: 'Figtree-Regular', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 17px !important;
  text-decoration: none;
  color: #ffc018 !important;
  -webkit-text-size-adjust: none;
  }
.mktoForm label.mktoLabel[for="ExpressConsent"] sup {
  font-family: 'Figtree-Regular', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 9px !important;
  line-height: 17px !important;
  -webkit-text-size-adjust: none;
  }
.mktoForm label.mktoLabel[for="referralConsent"],
.mktoForm label.mktoLabel[for="referralConsent"] span,
.mktoForm label.mktoLabel[for="referralConsent"] a {
  font-family: 'Figtree-Regular', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 17px !important;
  -webkit-text-size-adjust: none;
  }
.mktoForm label.mktoLabel[for="referralConsent"] sup {
  font-family: 'Figtree-Regular', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 9px !important;
  line-height: 17px !important;
  -webkit-text-size-adjust: none;
  }
.mktoForm label.mktoLabel[for="contestTerms"],
.mktoForm label.mktoLabel[for="contestTerms"] span,
.mktoForm label.mktoLabel[for="contestTerms"] a {
  font-family: 'Figtree-Regular', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 17px !important;
  -webkit-text-size-adjust: none;
  }
.mktoForm label.mktoLabel[for="contestTerms"] sup {
  font-family: 'Figtree-Regular', arial, sans-serif !important;
  font-weight: normal !important;
  font-size: 9px !important;
  line-height: 17px !important;
  -webkit-text-size-adjust: none;
  }

.mktoForm input[type=text],
.mktoForm input[type=url],
.mktoForm input[type=email],
.mktoForm input[type=tel],
.mktoForm input[type=number],
.mktoForm input[type=date],
.mktoForm select.mktoField,
.mktoForm textarea.mktoField{
  -webkit-appearance: none !important;
  background-color:#e9dfee !important;
  line-height:1.5em !important;
  color:#000 !important;
  border:none !important;
  -webkit-border-radius:0 !important;
  -moz-border-radius:0 !important;
  border-radius:0 !important;
  padding:0.2em 0.3em !important;
  box-shadow:none !important;
  min-height:1.9em !important;
  padding: 10px !important;
  margin-top: 8px !important;
}
.mktoForm input[type=text]:focus,
.mktoForm input[type=url]:focus,
.mktoForm input[type=email]:focus,
.mktoForm input[type=tel]:focus,
.mktoForm input[type=number]:focus,
.mktoForm input[type=date]:focus,
.mktoForm select.mktoField:focus,
.mktoForm textarea.mktoField:focus{
  outline:none !important;
  border:none !important;
  color:#000 !important;
}

.mktoForm select.mktoField::-ms-expand {
  display: none !important;
}

.mktoForm select.mktoField {
  -moz-appearance: none !important;
  text-indent: 0.01px !important;
  text-overflow: "" !important;
  padding:10px !important;
}


.mktoForm div.mktoLogicalField {
  border:1px solid transparent !important;
  -webkit-border-radius:7px !important;
  -moz-border-radius:7px !important;
  border-radius:7px !important;
}

.mktoForm input.mktoField.mktoInvalid,
.mktoForm select.mktoField.mktoInvalid,
.mktoForm textarea.mktoField.mktoInvalid,
.mktoForm div.mktoLogicalField.mktoInvalid{
  background: #ffd9d9 !important;
  border: none !important;
}

.mktoForm .mktoRadioList > label,
.mktoForm .mktoCheckboxList > label{
  margin-bottom:0.6em !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.mktoForm input[type=checkbox],
.mktoForm input[type=radio] {
  width:1em !important;
  height:1em !important;
  opacity:0 !important;
}

.mktoForm input[type=checkbox] + label:before,
.mktoForm input[type=radio] + label:before
{
  content: "" !important;  
  position:absolute !important;
  width:1em !important;
  height:1em !important;  
  margin-left:-1.5em !important;
  line-height:1em !important;
  background-color:#fff !important;
  box-shadow:inset 1px 1px 4px 1px #ddd !important;
  font-family:Arial, sans-serif !important;
  font-size:13px !important;
  border:1px solid #aeb0b6 !important;
}

.mktoForm .mktoLabelToLeft input[type=checkbox] + label:before,
.mktoForm .mktoLabelToLeft input[type=radio] + label:before{
  margin-left:0 !important;
  right:0.3em !important;
}

.mktoForm input[type=checkbox]:checked + label:before,
.mktoForm input[type=radio]:checked + label:before {
  text-align:center !important;
  color:#000 !important;
}

.mktoForm input[type=checkbox]:focus + label:before,
.mktoForm input[type=radio]:focus + label:before{
  border:1px solid #888 !important;
}

.mktoForm input[type=checkbox] + label:before{
  border-radius:2px !important;

}
.mktoForm input[type=checkbox]:checked + label:before{
  content:"\2713 " !important;
}

.mktoForm input[type=radio] + label:before{
  border-radius:1em !important;
}


.mktoForm.mktoNoCheckedSupport input[type=checkbox] + label:before,
.mktoForm.mktoNoCheckedSupport input[type=radio] + label:before{
  display:none !important;
}
.mktoForm.mktoNoCheckedSupport select.mktoField,
.mktoForm.ie7 select.mktoField,
.mktoForm.ie6 select.mktoField {
  background-image:none !important;
  padding-right:0.3em !important;
}

.mktoForm textarea.mktoField{
  resize: none !important;
}

button.mktoButton {
	padding: 14px 35px 12px 35px !important;
	margin: 0 5px 5px 0 !important;
	font-family: 'Figtree-Medium', arial, sans-serif !important;
	letter-spacing: .3px !important;
	font-size: 13px !important;
	line-height: 16px !important;
	text-transform: uppercase !important;
	text-align: left !important;
	color: #fff !important;
	background: #440e62 !important;
	border: none !important;
	box-shadow: none !important;
	}

@media screen and (max-width: 510px) {
.mktoButtonWrap {
	margin-left: 0 !important;
	}
}
`;
